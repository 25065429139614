<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'button',
    },
    theme: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['primary','secondary', 'tertiary', 'ghost', 'destructive', 'destructive-secondary', 'inverted', ''].includes(value)
        }
    },
    iconOnly: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['large','small', 'tiny'].includes(value) || !value
        }
    }
});
const setSize = computed(() => {
    switch (props.size) {
        case 'large':
            return 'btn--large';
        case 'small':
            return 'btn--small';
        case 'tiny':
            return 'btn--tiny';
        default:
            return 'btn--default';
    }
})

const setStyle = computed(() => {
    switch (props.theme) {
        default:
        case 'primary':
            return 'btn--primary';
        case 'secondary':
            return 'btn--secondary';
        case 'tertiary':
            return 'btn--tertiary';
        case 'ghost':
            return 'btn--ghost';
        case 'destructive':
            return 'btn--destructive';
        case 'destructive-secondary':
            return 'btn--destructive-secondary';
        case 'inverted':
            return 'btn--inverted';
    }
})
</script>

<template>
    <button :type="type" :class="[setSize, setStyle, {'btn--icon': iconOnly}]">
        <slot />
        <span v-if="loading" class="icon-fluent-spinner-ios-20-filled !size-6 animate-spin"></span>
    </button>
</template>
